import "jquery";
import "./style.scss";

import Router from "./util/Router";
import common from "./routes/common-route";
import home from "./routes/home-route";
import join from "./routes/join-route";
import resetPassword from "./routes/resetPassword-route";
import welcome from "./routes/welcome-route";
import searchResults from "./routes/search-route";

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */

const routes = new Router({
    /** All pages */
    common,
    /** Home page */
    home,
    /** Join page */
    join,
    /** Reset Password page */
    resetPassword,
    /** Welcome page */
    welcome,
    /** Search page */
    searchResults,
});
/** Load Events */
jQuery(document).ready(() => routes.loadEvents());
