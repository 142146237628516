import { Button, Spinner } from "@wordpress/components";
import useZypeAPIPromise from "../../../../util/zypeAPIPromise";
import { useState, useEffect } from "@wordpress/element";
import $ from "jquery";

import "./SubmitButton.scss";

export default function SubmitButton({
  passwordOrEmail,
  email,
  disabled,
  newPassword,
  passwordToken,
  passwordStrength,
  updateDisplayState,
}) {
  const [isEnabled, toggleIsEnabled] = useState(false);
  const [isProcessing, toggleIsProcessing] = useState(false);
  useEffect(() => {
    if (
      disabled == false &&
      ((passwordOrEmail === "password" && passwordStrength === "strong") || passwordOrEmail === "email")
    ) {
      toggleIsEnabled(true);
    }
  }, [disabled]);
  async function sendPutRequestToZypeToChangePassword() {
    // toggle ui
    toggleIsEnabled(false);
    toggleIsProcessing(true);

    // ajax request
    const ajaxUrl = window.location.origin + "/wp-content/themes/gttv-2021/src/util/zype-ajax.php";
    let data = { type: passwordOrEmail };
    switch (data.type) {
      case "email":
        data.email = email;
        break;
      case "password":
        data.password = newPassword;
        data.password_token = passwordToken;
      default:
        // no default necessary
        break;
    }
    $.post(ajaxUrl, data, (response) => {
      if (response == "error") {
        updateDisplayState({
          display: "error",
        });
      } else {
        toggleIsProcessing(false);
        updateDisplayState({
          display: `${response === "success_password" ? "successReset" : "successEmail"}`,
        });
      }
    });
  }

  return (
    <div className="reset-password-button-wrapper">
      <Button disabled={!isEnabled} onClick={sendPutRequestToZypeToChangePassword}>
        Submit
      </Button>
      {isProcessing && <Spinner />}
    </div>
  );
}
