// The TilesGrid component provides a grid container for all the "Video/Playlist Tiles" that a user can interact with.
// Tiles Grid component receives an array of Zype Video Objects and will return a Tile Component or each of those objects.
import React, { useState, useEffect } from "react";
import { default as Tile } from "../TileProto/TileProto";
import Button from "../Button/Button";
import "./TilesGrid.scss";

function TilesGrid(props) {
    const { collectionOfTilesData, paginationInfo } = props;
    //   console.log("collectionOfTilesData", collectionOfTilesData);
    //   console.log("paginationInfo", paginationInfo);

    function handleVideoTileClick(event) {
        // https://javascript.info/bubbling-and-capturing
        // Using CurrentTarget allows us to tap into the element with the onclick handler on it, in this case the thumbnail.
        // Thumbnail also has a custom data-attribute called data-tile-id, which we can leverage here to send the user to the watch page.
        window.location.assign(`${window.location.origin}/watch/?id=${event.currentTarget.dataset.tileId}`);
    }
    if (collectionOfTilesData.length >= 0) {
        return (
            <div className="zype-playlist-tiles-grid">
                {collectionOfTilesData.map((tileData) => {
                    return <Tile key={tileData._id} data={tileData} action={handleVideoTileClick}></Tile>;
                })}
                {paginationInfo.pages !== 0 && (
                    <Button
                        label="Show 10 more"
                        text="Show 10 more"
                        variant="primary"
                        onClick={() => {
                            alert("did the logic to show 10 more");
                        }}
                    />
                )}
            </div>
        );
    } else {
        return <p>no results</p>;
    }
}
export default TilesGrid;
