import { useEffect, useState, render } from "@wordpress/element";
import "./Login.scss";
import Modal from "../Modal/Modal";
import $ from "jquery";

function addScriptToBody(scriptSRC, content) {
    const body = document.getElementsByTagName("body")[0];
    const script = document.createElement("script");
    script.type = "text/javascript";
    switch (true) {
        case scriptSRC?.length > 0:
            script.src = scriptSRC;
            break;
        case content !== null:
            script.textContent = content;
            break;
        default:
            break;
    }
    body.appendChild(script);
}

function pollForZypeObject(callback) {
    let zypeCheckerInterval = setInterval(() => {
        if (window.zype) {
            clearInterval(zypeCheckerInterval);
            callback(true);
        }
    }, 100);
}

function addSignInModalClickEventToAnchorTag() {
    let signInLinkParagraph = $(".subscribe-link")[0];
    let signInLinkAnchor = signInLinkParagraph.getElementsByTagName("a")[0];
    signInLinkAnchor.addEventListener("click", (e) => {
        e.preventDefault();
        const modalRoot = $("#modal_root")[0];
        render(<Modal isSubscribe></Modal>, modalRoot);
    });
}
function modalFunctionalityForLink({ isLogin, isSubscribe }, e) {
    e.preventDefault();
    const modalRoot = $("#modal_root")[0];
    render(<Modal isLogin={!isLogin} isSubscribe={!isSubscribe}></Modal>, modalRoot);
}

export default function Login({ isLogin, isSubscribe, isReset }) {
    const [zypeReadyState, toggleZypeReadyState] = useState(false);
    useEffect(() => {
        addScriptToBody(`https://play.zype.com/javascripts/subscription_embed.js`, null);
        pollForZypeObject(toggleZypeReadyState);
    }, []);
    useEffect(() => {
        if (zypeReadyState && (isLogin || isSubscribe)) {
            addScriptToBody(
                null,
                `zype.siteId = 'CxDhlP4TwOiqcsLzfPqKIgoYfD4r10n9TXl2SdGjFtH549rSGfbdPAQycIdB0OEr';zype.profile="true";zype.${
                    isLogin ? "profileEmbed" : "signUpEmbed"
                }("signUpEmbedModal");
        `
            );
        }
    }, [zypeReadyState]);
    const ModalLink = (props) => (
        <div className="modal-link">
            <span>                
                <p className="faq-link-text">
                    {"Please "}
                    <a href="./help-center/" className="modal-link-button faq-link" target="_blank">
                        see our FAQ page for instructions
                    </a>
                    {" on adjusting your browser settings."}
                </p>

                {isSubscribe ? " (Requires a subscription)" : null}
            </span>
        </div>
    );
    return (
        <div className="login-modal-wrapper">
            {(isLogin || isSubscribe) && <div id="signUpEmbedModal"></div>}
            {isReset && (
                <iframe src="https://play.zype.com/CxDhlP4TwOiqcsLzfPqKIgoYfD4r10n9TXl2SdGjFtH549rSGfbdPAQycIdB0OEr/password/forgot?profile=true"></iframe>
            )}
            <div id="link_wrapper">
                <ModalLink isLogin={isLogin} isSubscribe={isSubscribe}></ModalLink>
            </div>
        </div>
    );
}
