/* GOSPELTRUTH.TV APP - 5dd556dbb0cc5b5e0ac9f8c3 */
import { render } from "@wordpress/element";
import SearchResults from "../components/SearchResults/SearchResults";

export default {
  init() {},
  finalize() {
    const searchRoot = document.getElementById("search_results_root");
    render(<SearchResults searchPrototypeSwitch="true"></SearchResults>, searchRoot);
  },
};
