/*------------------------------------------
       Bridge UTM Codes Feature
------------------------------------------ */
// Bridge stored UTM codes to success page because currently having to use zype iframe widget, which strips tracking codes, to do subscription and sign up processes.
// Generate an object of key, value pairs based on URLSearchParams constructor: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
// Search for the existence of {utm_source} QueryStringParameter, and store them in "utmCodes" local storage.
// look to src/routes/browse-route.js to append them.

//This function is for generating a map (object) of the current url params
export function generateParamsMap() {
    var params = new URLSearchParams(window.location.search);
    var paramsMap = {};
    params.forEach((value, param) => {
        paramsMap[param] = value;
    });
    return paramsMap;
}

//Grab the parameters and check if one of parameters is the keyNeedle (ex. utm_source)
export function findSetAndStoreUTMParams(keyNeedle) {
    let parameters = generateParamsMap();
    if (parameters?.[keyNeedle]) {
        //If one of the parameters is the same as the keyNeedle then save the params to local storage
        localStorage.setItem("utmCodes", JSON.stringify(parameters));
    }
}

// temporarily store any existence of Query string parameters to reappend them after appending utm codes
// based on URLSearchParams constructor: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
export function appendUTMParams(storedUTMCodes) {
    //Grab the current url params in order to append the stored utm codes to it
    var currentIncorrectUrl = new URL(window.location.href);
    var queryString = currentIncorrectUrl.search;
    var searchParams = new URLSearchParams(queryString);
    //If there are stored utm codes then append them to the url params
    if (storedUTMCodes) {
        storedUTMCodes = JSON.parse(storedUTMCodes);
        // loop through storedUTM object and append via key,value pair
        Object.entries(storedUTMCodes).forEach(([key, value]) => {
            searchParams.append(key, value);
        });
        currentIncorrectUrl.search = searchParams.toString();
        var correctedUrl = currentIncorrectUrl.toString();
        // Replace the url with the corrected one
        window.history.replaceState(null, null, correctedUrl);
        // Clean up local storage so hits don't duplicate.
        localStorage.removeItem("utmCodes");
    }
}
