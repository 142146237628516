export default async function useZypeAPIPromise(endpointFlag, id = "", endpointParams = {}, options = {}) {
    // console.log("endpointFlag: ", endpointFlag);
    // console.log("id: ", id);
    // console.log("endpointParams: ", endpointParams);
    // console.log("options: ", options);
    function determineEndpoint(flag) {
        switch (true) {
            case flag == "relationships":
                return `https://api.zype.com/playlists/relationships`;
            case flag == "relationshipsByParent":
                return `https://api.zype.com/playlists/relationships?parent_id=${id}`;
            case flag == "playlist":
                //switch comments for browse refactor
                return `https://api.zype.com/playlists/${id}/`;
            // return `https://api.zype.com/playlists?id=${id}`;
            case flag == "videos":
                return `https://api.zype.com/playlists/${id}/videos`;
            case flag == "listVideo":
                return `https://api.zype.com/videos/`;
            case flag == "viewVideo":
                return `https://api.zype.com/videos/${id}`;
            case flag == "schedules":
                return `https://api.zype.com/program_guides/`;
            case flag == "viewSchedule":
                return `https://api.zype.com/program_guides/${id}/entries`;
            case flag == "listConsumers":
                return `https://api.zype.com/consumers/`;
            case flag == "resetPassword":
                return `https://api.zype.com/consumers/reset_password`;
            case flag == "forgotPassword":
                return `https://api.zype.com/consumers/forgot_password`;
            default:
                console.error(`Flag: ${flag} not supported`);
                break;
        }
    }
    let endpoint = determineEndpoint(endpointFlag);
    let urlParams = Object.entries(endpointParams).map(([key, value]) => {
        return `&${key}=${value}`;
    });
    const apiKey = "GiD2nuFEeFeJgx5y-TOhYaglOM5-UsgqLlX8tMzHpx8w1mEtKRtVDWaWp_ez6pdX";
    let url;
    //switch comments for browse refactor
    endpointFlag === "relationshipsByParent"
        ? // endpointFlag === "relationshipsByParent" || endpointFlag === "playlist"
          (url = `${endpoint}&api_key=${apiKey}${urlParams.join("")}`)
        : (url = `${endpoint}?api_key=${apiKey}${urlParams.join("")}`);
    if (Object.entries(options).length > 0) {
        try {
            const response = await await fetch(url, options);
            return response;
        } catch (err) {
            return console.error("error:" + err);
        }
    }
    try {
        const response = await (await fetch(url)).json();
        return response;
    } catch (err) {
        return console.error("error:" + err);
    }
}
