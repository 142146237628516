import React, { useEffect, useState } from "react";
import useZypeAPIPromise from "../../util/zypeAPIPromise";

import Container from "../UI/Container";
import SearchBar from "../SearchBar/SearchBar";
import TilesGrid from "../TilesGrid/TilesGrid";

/*------------------------------------------
         Search Event Handlers
  ------------------------------------------ */
/* GOSPELTRUTH.TV APP - "5dd556dbb0cc5b5e0ac9f8c3" */

function SearchResults(props) {
  // Search Response will be used to populate Tiles Grid and Pagination Components
  const [searchResponse, setSearchResponse] = useState({});
  const [searchPagination, setSearchPagination] = useState({});
  //   Render Tiles state only set to true when response comes back
  const [renderTilesState, toggleRenderTiles] = useState(false);

  // Search function is taking the user input and applying it to the Videos Zype endpoint
  // https://docs.zype.com/reference/videos-1#listvideos-1
  async function handleBrowseSearch(userSearchInput) {
    let response = await useZypeAPIPromise(
      "listVideo",
      null,
      { "playlist_id.inclusive": "5dd556dbb0cc5b5e0ac9f8c3", "q": userSearchInput },
      {}
    );
    setSearchResponse(response.response);
    setSearchPagination(response.pagination);
  }
  //   This useEffect will check length of response state to see whether or not to render the Tiles Grid Component
  useEffect(() => {
    if (searchResponse.length > 0) {
      toggleRenderTiles(true);
    }
  }, [searchResponse]);
  return (
    <Container>
      <SearchBar action={handleBrowseSearch}></SearchBar>
      {renderTilesState && (
        <div>
          <h4>Select a tile to view the video.</h4>
          <TilesGrid collectionOfTilesData={searchResponse} paginationInfo={searchPagination}></TilesGrid>
        </div>
      )}
    </Container>
  );
}
export default SearchResults;
