import { FormToggle } from "@wordpress/components";
import { compose, withState } from "@wordpress/compose";
import "./PlayoutToggle.scss";

const applyWithState = withState({
  checked: false,
});
function PlayoutToggle({ checked, setState }) {
  return (
    <>
      <div className="playout-toggle">
        <span className="header">MODE</span>
        <div className="toggle-wrapper">
          <span className="toggle-item label">TV</span>
          <FormToggle
            checked={checked}
            onChange={() => setState((state) => ({ checked: !state.checked }))}
            className="toggle-item"
          />
          <span className="toggle-item label">Radio</span>
        </div>
      </div>
    </>
  );
}

export default compose(applyWithState)(PlayoutToggle);
