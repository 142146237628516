import { render } from "@wordpress/element";
import Modal from "../components/Modal/Modal";
import $ from "jquery";

function addClickEventsToCTAButtons() {
  let joinCTAButtons = Array.from($(".cta-button"));
  joinCTAButtons.forEach((button) => {
    button.addEventListener("click", (e) => {
      e.preventDefault();
      const modalRoot = $("#modal_root")[0];
      render(<Modal isSubscribe></Modal>, modalRoot);
    });
  });
}

function addSignInModalClickEventToAnchorTag() {
  let signInLinkParagraph = $(".sign-in-link")[0];
  let signInLinkAnchor = signInLinkParagraph.getElementsByTagName("a")[0];
  signInLinkAnchor.addEventListener("click", (e) => {
    e.preventDefault();
    const modalRoot = $("#modal_root")[0];
    render(<Modal isLogin></Modal>, modalRoot);
  });
}

export default {
  init() {},
  finalize() {
    addClickEventsToCTAButtons();
    addSignInModalClickEventToAnchorTag();
  },
};
