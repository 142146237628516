import { useEffect, useState } from "@wordpress/element";
import $ from "jquery";

export default function UserInfo({ passwordToken }) {
  const [userInfo, setUserInfo] = useState();
  const [renderReady, toggleRenderReady] = useState(true);
  useEffect(() => {
    async function requestUserDataFromZype(token) {
      // ajax request
      const ajaxUrl = window.location.origin + "/wp-content/themes/gttv-2021/src/util/zype-ajax.php";
      let data = { type: "token", password_token: token };
      await $.post(ajaxUrl, data, (response) => {
        if (response == "error") {
          console.warn("Something went wrong requesting user info.");
        } else {
          response = JSON.parse(response);
          setUserInfo(response?.response[0]);
        }
      });
    }
    if (passwordToken) {
      requestUserDataFromZype(passwordToken);
    }
  }, []);
  useEffect(() => {
    if (userInfo) {
      toggleRenderReady(true);
    }
  }, [userInfo]);
  /*------------------------------------------
         Render
  ------------------------------------------ */
  return (
    <div>
      {renderReady && (
        <>
          <p>Name: {userInfo?.name}</p>
          <p>Email: {userInfo?.email}</p>
        </>
      )}
    </div>
  );
}
