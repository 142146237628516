import { render } from "@wordpress/element";
import ResetPasswordApp from "../components/ResetPasswordApp/ResetPasswordApp";

function generateParamsMap() {
  return new Promise((resolve) => {
    var params = new URLSearchParams(window.location.search);
    if (!params.has("password_token")) {
      resolve({ password_token: undefined });
    } else {
      var paramsMap = {};
      params.forEach((value, param) => {
        paramsMap[param] = value;
      });
      resolve(paramsMap);
    }
    return;
  });
}

export default {
  init() {},
  async finalize() {
    // JavaScript to be fired on all /reset-password page, after page specific JS is fired
    let root = document.getElementById("reset_root");
    let paramsMap = await generateParamsMap();
    render(<ResetPasswordApp passwordToken={paramsMap?.password_token}></ResetPasswordApp>, root);
    root.classList.remove("animate");
  },
};
