// Extending the Wordpress Button component, which let users take actions and make choices with a single click or tap.
// https://developer.wordpress.org/block-editor/reference-guides/components/button/#props

import { Button as WordpressButton } from "@wordpress/components";
import styles from "./Button.module.scss";

export default function Button({
  disabled,
  href,
  variant,
  isDestructive,
  isSmall,
  isPressed,
  isBusy,
  focus,
  target,
  className,
  icon,
  iconSize,
  iconPosition,
  text,
  showTooltip,
  tooltipPosition,
  shortcut,
  label,
  onClick,
}) {
  return (
    <WordpressButton
      onClick={onClick}
      disabled={disabled}
      href={href}
      variant={variant}
      isDestructive={isDestructive}
      isSmall={isSmall}
      isPressed={isPressed}
      isBusy={isBusy}
      focus={focus}
      target={target}
      className={`${styles.button} ${className ? className : ""}`}
      icon={icon}
      iconSize={iconSize}
      iconPosition={iconPosition}
      text={text}
      showTooltip={showTooltip}
      tooltipPosition={tooltipPosition}
      shortcut={shortcut}
      label={label}
    ></WordpressButton>
  );
}
