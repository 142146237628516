import { UseTruncateChars } from "../../../util/useTruncate";

export default function NowPlaying(props) {
  const { nowPlayingData } = props;
  // Get Start Time of scheduleItem
  const startTime = new Date(nowPlayingData.start_time_with_offset).toLocaleTimeString("en", {
    // timeStyle: "short",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
    timeZoneName: "short",
    timeZone: "America/Denver",
  });
  const endTime = new Date(nowPlayingData.end_time_with_offset).toLocaleTimeString("en", {
    timeStyle: "short",
    hour12: true,
    timeZone: "UTC",
  });
  // Get End Time of scheduleItem
  return (
    <>
      <div className="now-playing-container">
        <div className="now-playing-item">
          <div className="start-time">{startTime}</div>
          <div className="now-playing-title">
            {nowPlayingData.title?.length >= 40 ? UseTruncateChars(nowPlayingData.title, 40) : nowPlayingData.title}
          </div>
        </div>
      </div>
    </>
  );
}
