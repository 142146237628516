// Extending the Wordpress TextControl, which lets users enter and edit text
// https://developer.wordpress.org/block-editor/reference-guides/components/text-control/
import { useState } from "@wordpress/element";
import styles from "./Input.module.scss";

export default function Input(props) {
  const [userInput, setUserInput] = useState("");
  return (
    <div className={styles["input-container"]}>
      <label className={styles.label} htmlFor={props.id}>
        {props.label}
      </label>
      <input
        className={styles.input}
        type={props.type}
        placeholder={props.placeholder}
        value={userInput}
        onChange={(event) => {
          setUserInput(event.target.value);
        }}
        name={props.id}
        id={props.id}
      />
    </div>
  );
}
