import { UseTruncateChars } from "../../../util/useTruncate";

export default function UpcomingItems(props) {
  const { upcomingItems } = props;
  const upcomingElements = upcomingItems.map((upcomingData) => {
    // Get Start Time of scheduleItem
    const startTime = new Date(upcomingData.data.start_time_with_offset).toLocaleTimeString("en", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
      timeZoneName: "short",
      timeZone: "America/Denver",
    });
    // Get End Time of scheduleItem
    const endTime = new Date(upcomingData.data.end_time_with_offset).toLocaleTimeString("en", {
      timeStyle: "short",
      hour12: true,
      timeZone: "UTC",
    });
    return (
      <>
        <div key={upcomingData.data._id} className="upcoming-item-container">
          <div className="start-time">{startTime}</div>
          <div className="title">
            {upcomingData.data.title?.length >= 40
              ? UseTruncateChars(upcomingData.data.title, 40)
              : upcomingData.data.title}
          </div>
        </div>
      </>
    );
  });
  return <div className="upcoming-items-container">{upcomingElements}</div>;
}
