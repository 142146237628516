import { useState, useEffect } from "@wordpress/element";
import "./PlayoutChannel.scss";

export default function PlayoutChannel(props) {
  const { zypeVideoPlayerID, isAutoPlay, isLoop } = props;
  const [videoID, setVideoID] = useState();
  const [idReadyState, toggleIDReadyState] = useState(false);
  const [playerReadyState, togglePlayerReadyState] = useState(false);
  /*------------------------------------------
           HELPER FUNCTIONS
    ------------------------------------------ */
  function addScriptToBody(scriptSRC) {
    const body = document.getElementsByTagName("body")[0];
    const script = document.createElement("script");
    script.src = scriptSRC;
    body.appendChild(script);
  }
  /*------------------------------------------
           USE EFFECTS
    ------------------------------------------ */
  useEffect(() => {
    setVideoID(zypeVideoPlayerID);
    toggleIDReadyState(true);
  }, []);
  useEffect(() => {
    if (idReadyState) {
      addScriptToBody(
        `https://player.zype.com/embed/${videoID}.js?api_key=lme28nzzmpUhyudZK-aAtYVcZ5zUFWl9OpJ77GwDrPcGi6cExyVuoKU6YwR-n_Mw&app_key=v8GR3uygk0s9NdbJ6wyJFp3Z3dZpTU9Zdb75ljtk4-SMekzDZLcsbKvwykGnBQq-${
          isAutoPlay ? "&autoplay=true" : ""
        }${isLoop ? "&loop=true" : ""}`
      );
    }
  }, [videoID]);
  return (
    <>
      {idReadyState && (
        <>
          <div className={`video-container animate`}>
            <div id={`zype_${videoID}`}></div>
          </div>
        </>
      )}
    </>
  );
}
