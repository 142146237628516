import * as Navigation from "../util/navigation";
import * as GoogleTagManager from "../util/googleTagManager";
import getEPGData from "../util/getEPGData";
import { render } from "@wordpress/element";
import LiveStreamTriggerBox from "../components/LiveStreamTriggerBox/LiveStreamTriggerBox.js";
import { generateParamsMap, findSetAndStoreUTMParams } from "../util/storeUtmCodes";

// async function checkIsLive() {
//     let response = await fetch(
//         "https://connect.haivision.com/api/organizations/316b9d16-63b0-4e3a-8a57-68c629d2fe32/players/2b62f99d-9f8a-436a-b94c-bc53a2723ea6/embed/7609f544-f017-408d-9baf-da1ae84fc64c"
//     );
//     response = await response.json();
//     if (!response.stream) {
//         response = await checkIsLiveLegacy();
//         if (response) {
//             return "live";
//         } else {
//             return false;
//         }
//     }
//     return response?.stream?.state;
// }

async function checkIsLiveSardius() {
    let calendarResponse = await fetch(
        "https://api.prod-api.sardius.media/calendars/EF87A10fBCABb74/site_D7f6be918D/live"
    );
    calendarResponse = await calendarResponse.json();
    let akamaizedHLSResponse = await fetch(
        "https://EF87A10fBCABb74.akamaized.net/hls/live/2099452/Gospel/playlist.m3u8"
    );
    akamaizedHLSResponse = await akamaizedHLSResponse?.ok;
    let sardiusHLSResponse = await fetch(
        "https://storage.sardius.media/archives/EF87A10fBCABb74/events/site_D7f6be918D/playlist.m3u8"
    );
    sardiusHLSResponse = await sardiusHLSResponse?.ok;
    if (calendarResponse.length !== 0) {
        const currentTimeStamp = Date.now();
        const startTimeStamp = Date.parse(calendarResponse[0].start);
        const endTimeStamp = Date.parse(calendarResponse[0].end);
        if (startTimeStamp <= currentTimeStamp && endTimeStamp >= currentTimeStamp) {
            if (akamaizedHLSResponse && sardiusHLSResponse) {
                return "live";
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
    return false;
}
async function initLiveStreamModal() {
    const liveStreamBoxRoot = document.getElementById("live_stream_notification_root");
    let isLive =
        // "live";
        await checkIsLiveSardius();
    if (isLive !== "live") {
        let liveStreamCheckerInterval = setInterval(async () => {
            isLive = await checkIsLiveSardius();
            if (isLive == "live") {
                render(
                    <LiveStreamTriggerBox intervalObject={liveStreamCheckerInterval}></LiveStreamTriggerBox>,
                    liveStreamBoxRoot
                );
            } else {
                null;
            }
        }, 60000);
    } else {
        render(<LiveStreamTriggerBox></LiveStreamTriggerBox>, liveStreamBoxRoot);
    }
}
// async function checkIsLiveLegacy() {
//     let response = await fetch(
//         "https://andrewwomackhls-i.akamaihd.net/hls/live/571281/wommacklivehlspublic/video_0.m3u8"
//     );
//     return response?.ok;
// }
// async function initLiveStreamModalLegacy() {
//     const liveStreamBoxRoot = document.getElementById("live_stream_notification_root");
//     let isLive = await checkIsLiveLegacy();
//     if (!isLive) {
//         let liveStreamCheckerInterval = setInterval(async () => {
//             isLive = await checkIsLiveLegacy();
//             if (isLive) {
//                 render(
//                     <LiveStreamTriggerBox intervalObject={liveStreamCheckerInterval}></LiveStreamTriggerBox>,
//                     liveStreamBoxRoot
//                 );
//             } else {
//                 null;
//             }
//         }, 60000);
//     } else {
//         render(<LiveStreamTriggerBox></LiveStreamTriggerBox>, liveStreamBoxRoot);
//     }
// }

export default {
    async init() {
        // JavaScript to be fired on all pages
        const menuItemsWithChildren = document.getElementsByClassName("menu-item-has-children");
        const hamburgerMenuIcon = document.getElementsByClassName("hamburger-menu-24")[0];
        Navigation.addChevronIconsToParentMenuItems(menuItemsWithChildren);
        Navigation.checkIfChildMenuItemIsActive(menuItemsWithChildren);
        Navigation.addTopMenuItemsEventListeners(menuItemsWithChildren);
        //Certain templates don't have hamburger menu's
        if (hamburgerMenuIcon) {
            Navigation.addExpandFunctionToHamburgerMenuIcon(hamburgerMenuIcon);
        }
        Navigation.addModalClickEventToLoginItem();
        Navigation.addModalClickEventToPasswordResetItem();
        let EPGData = await getEPGData();
        Navigation.addModalClickEventToScheduleItem(EPGData);
        GoogleTagManager.injectGTMHeadTag();
        GoogleTagManager.injectGTMBodyTag();
        //Grab the parameters in order to check for subscribe = true
        let parameters = generateParamsMap();
        //If subscribe = true then store the UTM parameters (if utm source exist) in local storage
        if (!parameters?.subscribe) {
            findSetAndStoreUTMParams("utm_source");
        }
    },
    finalize() {
        initLiveStreamModal();
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
