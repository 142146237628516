import { useState, useEffect } from "@wordpress/element";

export default function ConfirmPassword({ newPassword, handleMatch }) {
  const [currentValue, setCurrentValue] = useState("");
  function analyzePasswordMatch(value) {
    if (value === newPassword) {
      handleMatch(true);
    } else {
      handleMatch(false);
    }
  }
  useEffect(() => {
    if (newPassword !== "") {
      analyzePasswordMatch(currentValue);
    }
  }, [newPassword]);
  return (
    <div>
      <p>
        <label htmlFor="confirm-password">Confirm Password: </label>
      </p>
      <p>
        <input
          type="password"
          name="confirm-password"
          onChange={(e) => {
            analyzePasswordMatch(e.target.value);
            setCurrentValue(e.target.value);
          }}
          value={currentValue}
        />
      </p>
    </div>
  );
}
