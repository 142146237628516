export function UseTruncateWords(str, numWords) {
    // truncate words to certain number of words
    let truncatedStr = str.split(" ").splice(0, numWords).join(" ");
    // console.log("trunc", truncatedStr);
    // console.log("trunc2", str.split(" ").splice(0, numWords).length);

    // If the truncated string ends in a "puntuctation" or "space" remove it before appending elipses. Prevents cases like: "?...", "...." or ". ..."
    if (/[\W]/.test(truncatedStr[truncatedStr.length - 1])) {
        truncatedStr = truncatedStr.substring(0, truncatedStr.length - 1);
    }

    return str.split(" ").splice(0, numWords).length >= numWords ? truncatedStr + "..." : truncatedStr;
}

export function UseTruncateChars(str, numChar) {
    // truncate words to certain number of characters
    if (str.length <= numChar) {
        return str;
    }
    let truncatedStr = str.slice(0, numChar);
    // If the truncated string ends in a "puntuctation" or "space" remove it before appending elipses. Prevents cases like: "?...", "...." or ". ..."
    if (/[\W]/.test(truncatedStr[truncatedStr.length - 1])) {
        truncatedStr = truncatedStr.substring(0, truncatedStr.length - 1);
    }
    return truncatedStr + "...";
}
