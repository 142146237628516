import $ from "jquery";
export function injectGTMHeadTag() {
    //Code for new GTM account to connect to GA4
    let commentBeginGA4 = document.createComment("Google Tag Manager");
    let commentEndGA4 = document.createComment("End Google Tag Manager");
    let scriptTagGA4 = document.createElement("script");
    scriptTagGA4.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-5NL58R7');`;
    let injectElementsGA4 = [commentBeginGA4, scriptTagGA4, commentEndGA4];
    let targetGA4 = $("head");
    $(targetGA4).prepend(...injectElementsGA4);
}
export function injectGTMBodyTag() {    
    //Code for new GTM account to connect to GA4
    let commentBeginGA4 = document.createComment("Google Tag Manager (noscript)");
    let commentEndGA4 = document.createComment("End Google Tag Manager (noscript)");
    let scriptTagGA4 = document.createElement("noscript");
    let iframeElementGA4 = document.createElement("iframe");
    $(iframeElementGA4).attr("src", "https://www.googletagmanager.com/ns.html?id=GTM-5NL58R7");
    $(iframeElementGA4).attr("height", "0");
    $(iframeElementGA4).attr("width", "0");
    $(iframeElementGA4).attr("style", "display:none;visibility:hidden;");
    scriptTagGA4.append(iframeElementGA4);
    let injectElementsGA4 = [commentBeginGA4, scriptTagGA4, commentEndGA4];
    let targetGA4 = $("body");
    $(targetGA4).prepend(...injectElementsGA4);
}
