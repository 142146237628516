import useZypeAPIPromise from "./zypeAPIPromise";

export default async function getEPGData() {
  function getDateWithTimeZoneOffset(inputDate) {
    const offset = inputDate.getTimezoneOffset();
    inputDate = new Date(inputDate.getTime() - offset * 60 * 1000);
    return inputDate;
  }

  function getDateInYYYYMMDDFormat(inputDate) {
    inputDate = getDateWithTimeZoneOffset(inputDate);
    return inputDate.toISOString().split("T")[0];
  }
  function getSaturday(inputDate) {
    let resultDate = getDateWithTimeZoneOffset(inputDate);
    return resultDate.setDate(inputDate.getDate() + ((7 + 6 - inputDate.getDay()) % 7));
  }
  function getPastSunday(inputDate) {
    let resultDate = getDateWithTimeZoneOffset(inputDate);
    // if result date is sunday then use it as the beginning date of the spanning week;
    if (resultDate.getDay() == 0) {
      return resultDate;
      // Set to most recent past Sunday: current date + Day index(0) - current day number
    } else
      return resultDate.setUTCDate(resultDate.getUTCDate() + 0 - (resultDate.getUTCDay() || 7));
  }
  function getPlayoutName(epgFlag = null) {
    // Pull the date from JS
    const saturdayDate = getSaturday(new Date());
    const saturdayFormattedDate = getDateInYYYYMMDDFormat(new Date(saturdayDate));
    const pastSundayDate = getPastSunday(new Date());
    const pastSundayFormattedDate = getDateInYYYYMMDDFormat(new Date(pastSundayDate));
    // Stitch together a name based on the Format: GTTV-Playout-{YYYY}-{MM}-{DD}_{YYYY}-{MM}-{DD} where the last Date Group is the saturday of that week.
    switch (true) {
      case epgFlag?.length > 0:
        return `gttv-epg-${pastSundayFormattedDate}_${saturdayFormattedDate}`;
      default:
        return `GTTV-Playout-${pastSundayFormattedDate}_${saturdayFormattedDate}`;
    }
  }
  function matchScheduleByPlayoutTitle(schedules, needleTitle) {
    var found = false;
    var match;
    function scour(data) {
      for (let i = 0; i < data.length; i++) {
        const schedule = data[i];
        if (schedule.name == needleTitle) {
          found = true;
          match = schedule;
          break;
        } else {
          if (found) {
            break;
          }
        }
      }
    }
    scour(schedules);
    return match;
  }
  // FailState ID
  const failStateID = "5f46eb0e0620d60001d77178";
  const playoutName = getPlayoutName("epg");
  // Make a request to the Zype API for all schedules
  // const allSchedulesResponse = await useZypeAPIPromise("schedules", null, { per_page: 500 });
  //   Bug GRC-180: Making a request to all programs by filtering by title, to workaround pagination issues. If this fails, uncomment the above method which calls 500 items per page. Which is MVP Blunt force solution.
  const allSchedulesResponse = await useZypeAPIPromise("schedules", null, { q: playoutName });
  const allSchedules = allSchedulesResponse.response;
  const matchedSchedule = matchScheduleByPlayoutTitle(allSchedules, playoutName);
  // Get the Zype object id that matches our title request.
  const matchedScheduleID = matchedSchedule?._id ? matchedSchedule._id : failStateID;
  // Make a request to the Zype API with that id param.
  const matchedScheduleResponse = await useZypeAPIPromise("viewSchedule", matchedScheduleID, {
    disable_pagination: true,
  });
  return matchedScheduleResponse.response;
}
