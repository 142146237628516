import { UseTruncateChars } from "../../../../util/useTruncate";
import cn from "classnames";

export default function ScheduleItem({ data, isAlternate, isNowPlaying }) {
  const startTime = new Date(data.start_time_with_offset).toLocaleTimeString("en", {
    // timeStyle: "short",
    // hour12: true,
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
    timeZoneName: "short",
    // Changed from "UTC" to attempt to show schedule in user's timezone
    // MVP is keeping all times in "Denver" time to avoid having to be 2 weeks ahead, (timezone issues)
    timeZone: "America/Denver",
  });
  return (
    <div
      key={data._id}
      className={cn("schedule-item", { "is-alternate": isAlternate, "is-now-playing": isNowPlaying })}
    >
      <div className="thumbnail-wrapper">
        <img className="schedule-thumbnail animate" src={data.category[0]} alt={`Logo for the '${data.title}' show`} />
      </div>
      <div className="info-wrapper">
        <div className="start-time">{startTime}</div>
        <div className="title">{data.title?.length >= 75 ? UseTruncateChars(data.title, 75) : data.title}</div>
      </div>
    </div>
  );
}
