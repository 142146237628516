import { useState, useEffect } from "@wordpress/element";
import SubmitButton from "../SubmitButton/SubmitButton";
export default function EmailInput({ updateDisplayState }) {
  const [emailValue, updateEmailValue] = useState("");
  function isValidEmail(email) {
    var emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return !!email && typeof email === "string" && email.match(emailRegex);
  }

  return (
    <>
      <p>
        <label htmlFor="email">Email: </label>
      </p>
      <p>
        <input
          type="email"
          id="email"
          className="form-control"
          name="email"
          onChange={(e) => {
            updateEmailValue(e.target.value);
          }}
          value={emailValue}
        />
      </p>
      <SubmitButton
        passwordOrEmail={"email"}
        email={emailValue}
        disabled={!isValidEmail(emailValue)}
        updateDisplayState={updateDisplayState}
      ></SubmitButton>
    </>
  );
}
