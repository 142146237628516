import { render } from "@wordpress/element";
import Modal from "../components/Modal/Modal";
import ExpandIcon from "../../../../themes/gttv-2021/src/lib/icons/expand-more.svg";

export function toggleExpandClass(element, force) {
    element.classList.toggle("expanded", force);
    const chevron = element.parentElement.firstElementChild.lastElementChild;
    rotateChevron(chevron, force);
}
// TODO: Separate Expand and Collapse Functionality so that we can fine tune how submenus behave (e.g, if user scrolls)
function rotateChevron(chevronElement, force) {
    chevronElement.classList.toggle("collapse", force);
}

function expandFunctionalityToSubMenus(e, index, force) {
    //   Capture Target
    const target = e.target;
    const currentTarget = e.currentTarget;
    if (target == currentTarget) {
        // Prevent Page Navigation
        e.stopPropagation();
        e.preventDefault();
        //   Add Expand Menu
        const subMenu = document.getElementsByClassName("sub-menu")[index];
        toggleExpandClass(subMenu, force);
    }
}

export function addExpandSubmenuClickHandler(element, index) {
    element.firstElementChild.firstElementChild.addEventListener(
        "click",
        (e) => {
            expandFunctionalityToSubMenus(e, index);
        },
        true
    );
    //   For Hover
    element.firstElementChild.firstElementChild.addEventListener(
        "mouseenter",
        (e) => {
            expandFunctionalityToSubMenus(e, index, true);
        },
        true
    );
    element.firstElementChild.addEventListener(
        "mouseleave",
        (e) => {
            expandFunctionalityToSubMenus(e, index, false);
        },
        true
    );
    element.lastElementChild.addEventListener("mouseenter", (e) => {
        expandFunctionalityToSubMenus(e, index, true);
    });
    element.lastElementChild.addEventListener("mouseleave", (e) => {
        expandFunctionalityToSubMenus(e, index, false);
    });
}

export function disableTopLevelMenuClickDefault(menuItemsWithChildren) {
    Array.from(menuItemsWithChildren).forEach((menuItem, index) => {
        addExpandSubmenuClickHandler(menuItem, index);
    });
}

export function addTopMenuItemsEventListeners(menuItemsWithChildren) {
    disableTopLevelMenuClickDefault(menuItemsWithChildren);
}

export function checkIfChildMenuItemIsActive(menuItemsWithChildren) {
    Array.from(menuItemsWithChildren).forEach((menuItem) => {
        const subMenu = menuItem.getElementsByClassName("sub-menu")[0];
        const children = Array.from(subMenu.children);
        children.forEach((childMenuItem) => {
            // Has Class current-menu-item
            const activeChildMenuItem = Array.from(childMenuItem.classList).some((className) => {
                return className == "current-menu-item";
            });
            if (activeChildMenuItem) {
                //   Turned off for now because it was getting in the way
                // toggleExpandClass(subMenu);
            }
        });
    });
}

export function addExpandFunctionToHamburgerMenuIcon(element) {
    const hamburger = element;
    const mainNavMenu = document.getElementById("site_header_menu");
    hamburger.addEventListener("click", () => {
        toggleExpandClass(mainNavMenu);
    });
}

export function addModalClickEventToLoginItem() {
    let myAccountMenuItem = Array.from(document.getElementsByClassName("my-account-menu-item"));
    myAccountMenuItem.forEach((menuItem) => {
        menuItem.addEventListener("click", (e) => {
            e.preventDefault();
            render(<Modal isLogin></Modal>, document.getElementById("modal_root"));
        });
    });
}
export function addModalClickEventToPasswordResetItem() {
    let passwordResetItems = Array.from(document.getElementsByClassName("password-reset-button"));
    passwordResetItems.forEach((menuItem) => {
        menuItem.addEventListener("click", (e) => {
            e.preventDefault();
            render(<Modal isReset></Modal>, document.getElementById("modal_root"));
        });
    });
}

export function addModalClickEventToScheduleItem(EPGData) {
    let scheduleMenuItem = document.getElementsByClassName("schedule-menu-item")[0];
    if (scheduleMenuItem) {
        scheduleMenuItem.addEventListener("click", (e) => {
            e.preventDefault();
            render(<Modal isEPG EPGData={EPGData}></Modal>, document.getElementById("modal_root"));
        });
    }
}

function insertChevron(element) {
    const anchorWrapper = document.createElement("div");
    anchorWrapper.classList.add("anchor-wrapper");
    anchorWrapper.insertBefore(element.firstElementChild, anchorWrapper.childNodes[0]);
    const chevron = document.createElement("div");
    chevron.classList.add("chevron", "expand");
    chevron.innerHTML = ExpandIcon;
    anchorWrapper.appendChild(chevron);
    element.insertBefore(anchorWrapper, element.childNodes[0]);
}
export function addChevronIconsToParentMenuItems(menuItemsWithChildren) {
    Array.from(menuItemsWithChildren).forEach((menuItem) => {
        insertChevron(menuItem);
    });
}
