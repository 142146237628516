import { useState, useEffect, render } from "@wordpress/element";

import UserInfo from "./components/UserInfo/UserInfo";
import PasswordReset from "./components/PasswordReset/PasswordReset";
import EmailInput from "./components/EmailInput/EmailInput";
import Modal from "../Modal/Modal";

import $ from "jquery";

import "./ResetPasswordApp.scss";

export default function ResetPasswordApp({ passwordToken }) {
  const [displayState, setDisplayState] = useState({
    display: "reset",
  });
  const [isValidToken, validateToken] = useState(false);
  useEffect(() => {
    async function sendTokenRequestToValidate(token) {
      // ajax request
      const ajaxUrl = window.location.origin + "/wp-content/themes/gttv-2021/src/util/zype-ajax.php";
      let data = { type: "token", password_token: token };
      await $.post(ajaxUrl, data, (response) => {
        if (response == "error") {
          console.warn("Something went wrong requesting user info.");
        } else {
          response = JSON.parse(response);
          if (response?.response[0]) {
            validateToken(true);
          }
        }
      });
    }
    if (passwordToken) {
      sendTokenRequestToValidate(passwordToken);
    }
  }, []);
  const SuccessMessage = (props) => {
    let content;
    switch (props.display) {
      case "successEmail":
        content = (
          <div className="content">
            <h4>Success!</h4>
            <p>Please check your email shortly, and click the generated link from info@gospeltruth.tv.</p>
            <p>
              In the meantime, checkout our <a href="/">main page</a> to see what is currently playing.
            </p>
          </div>
        );
        break;
      case "successReset":
        content = (
          <div className="content">
            <h4>Success!</h4>
            <p>
              Password was reset successfully, please try to sign in using the{" "}
              <a
                onClick={() => {
                  render(<Modal isLogin></Modal>, document.getElementById("modal_root"));
                }}
                className="my-account-menu-item"
              >
                'Account' link here
              </a>
              , or in the menu. Or browse our On-demand content by visiting <a href="/browse">the Browse page</a>.
            </p>
          </div>
        );
        break;
      default:
        content = (
          <div className="content">
            <h4>Error!</h4>
            <p>Something went wrong. Please try again later.</p>
          </div>
        );
        break;
    }
    return <div className="message">{content}</div>;
  };
  return (
    <>
      <div className="reset-password-app-form-control">
        {displayState.display == "reset" && (
          <>
            <h1>Reset My Password</h1>
            {isValidToken && (
              <>
                <UserInfo passwordToken={passwordToken} />
                <PasswordReset passwordToken={passwordToken} updateDisplayState={setDisplayState}></PasswordReset>
              </>
            )}
            {!isValidToken && (
              <div className="input-email-wrapper">
                <p>Please enter the email associated with your account.</p>
                <EmailInput updateDisplayState={setDisplayState} />
              </div>
            )}
          </>
        )}
        {(displayState.display == "successEmail" || displayState.display == "successReset") && (
          <SuccessMessage display={displayState.display} />
        )}
        {displayState.display == "error" && <SuccessMessage display={displayState.display} />}
      </div>
    </>
  );
}
