import { useEffect, useRef, useState } from "@wordpress/element";

export default function DayItem({ handleClick, index, activeDay, day, sundayDate }) {
  const ref = useRef(null);
  const [elementWidth, setElementWidth] = useState(0);
  useEffect(() => {
    const elWidth = ref.current ? ref.current.offsetWidth : 0;
    setElementWidth(elWidth);
  }, [ref.current]);

  let date = new Date(sundayDate);
  //   Using the index to add days to sunday in order to account for end of month transitions mid-week
  date.setDate(new Date(sundayDate).getDate() + index);
  let month = date.getMonth() + 1;
  date = date.getDate();
  return (
    <div
      onClick={handleClick}
      ref={ref}
      className={`day-item ${index == activeDay ? "active" : ""}`}
      data-day-of-week={index}
      data-width={elementWidth}
    >
      <span data-day-of-week={index}>{day}</span>
      <span data-day-of-week={index}>
        {month}/{date}
      </span>
    </div>
  );
}
