import { unmountComponentAtNode, useEffect, useState } from "@wordpress/element";
import "./Modal.scss";
import Login from "../Login/Login";
import FullSchedule from "../EPGSchedule/FullSchedule/FullSchedule";
import classnames from "classnames";
import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();

export default function Modal({ isLogin, isSubscribe, isReset, isEPG, EPGData }) {
    const [mountScrollPosition, setMountScrollPosition] = useState(window.scrollY);
    function getScrollbarWidth() {
        // Creating invisible container
        const outer = document.createElement("div");
        outer.style.visibility = "hidden";
        outer.style.overflow = "scroll"; // forcing scrollbar to appear
        outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps
        document.body.appendChild(outer);

        // Creating inner element and placing it in the container
        const inner = document.createElement("div");
        outer.appendChild(inner);

        // Calculating difference between container's full width and the child width
        const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

        // Removing temporary elements from the DOM
        outer.parentNode.removeChild(outer);
        return scrollbarWidth;
    }
    function blockScrollToggle(toggleFlag = "enable") {
        // overflow: hidden; position: fixed; height: 100%; width: 100%; top: -379px;
        const body = document.getElementsByTagName("body")[0];
        const siteHeader = document.getElementById("site_header");
        switch (true) {
            case toggleFlag == "disable":
                body.style.top = `-${mountScrollPosition}px`;
                siteHeader.style.top = `${mountScrollPosition}px`;
                body.classList.toggle("scroll-disabled");
                body.style.paddingRight = `${getScrollbarWidth()}px`;
                break;
            case toggleFlag == "enable":
                body.classList.toggle("scroll-disabled");
                siteHeader.style.top = `0px`;
                body.style.paddingRight = "0px";
                window.scroll({ top: mountScrollPosition, behavior: "auto" });
                break;
            default:
                console.warn(`ToggleFlag: ${toggleFlag} not supported`);
                break;
        }
    }
    function addExitListenerToModal() {
        let modalOverlay = document.getElementById("modal_overlay");
        modalOverlay.addEventListener("click", handleExitClick, false);
    }
    // Event Listeners
    useEffect(() => {
        blockScrollToggle("disable");
        addExitListenerToModal();
        return () => {
            blockScrollToggle("enable");
        };
    }, []);
    function unMountModalComponent() {
        let modalRoot = document.getElementById("modal_root");
        modalRoot.classList.add("removed");
        setTimeout(() => {
            unmountComponentAtNode(modalRoot);
            modalRoot.classList.remove("removed");
        }, 250);
    }
    function handleExitClick(event) {
        switch (true) {
            case event?.target?.id == "modal_exit_icon":
            case event?.currentTarget?.id == "modal_exit":
            case event?.target?.id == "modal_overlay":
                unMountModalComponent();
                break;
            default:
                return;
        }
    }
    const ExitButton = () => {
        return (
            <div id="modal_exit" onClick={handleExitClick}>
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path
                        id="modal_exit_icon"
                        fill="currentColor"
                        d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                    />
                </svg>
            </div>
        );
    };
    const ModalContent = () => {
        switch (true) {
            case isLogin:
                return <Login isLogin></Login>;
            case isSubscribe:
                return <Login isSubscribe></Login>;
            case isReset:
                return <Login isReset></Login>;
            case isEPG:
                return <FullSchedule EPGData={EPGData}></FullSchedule>;
            default:
                return <div>Modal</div>;
        }
    };

    return (
        <div id="modal_overlay">
            <div id="modal_container" className={classnames({ isLogin, isEPG, isSubscribe, isReset })}>
                {
                    <>
                        <ExitButton />
                        <div id="modal_content">
                            <ModalContent></ModalContent>
                        </div>
                    </>
                }
            </div>
        </div>
    );
}
