import { render } from "@wordpress/element";
import PlayoutChannel from "../components/PlayoutChannel/PlayoutChannel";
import EPGSchedule from "../components/EPGSchedule/EPGSchedule";
import PlayoutToggle from "../components/PlayoutToggle/PlayoutToggle";
import useZypeAPIPromise from "../util/zypeAPIPromise";
import getEPGData from "../util/getEPGData";

function getDateWithTimeZoneOffset(inputDate) {
  const offset = inputDate.getTimezoneOffset();
  inputDate = new Date(inputDate.getTime() - offset * 60 * 1000);
  return inputDate;
}

function getDateInYYYYMMDDFormat(inputDate) {
  inputDate = getDateWithTimeZoneOffset(inputDate);
  return inputDate.toISOString().split("T")[0];
}
function getSaturday(inputDate) {
  let resultDate = getDateWithTimeZoneOffset(inputDate);
  return resultDate.setDate(inputDate.getDate() + ((7 + 6 - inputDate.getDay()) % 7));
}
function getPastSunday(inputDate) {
  let resultDate = getDateWithTimeZoneOffset(inputDate);
  // if result date is sunday then use it as the beginning date of the spanning week;
  if (resultDate.getDay() == 0) {
    return resultDate;
    // Set to most recent past Sunday: current date + Day index(0) - current day number
  } else return resultDate.setUTCDate(resultDate.getUTCDate() + 0 - (resultDate.getUTCDay() || 7));
}
function getDayOfWeek() {
  const weekDays = {
    0: "Sunday",
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
  };
  return weekDays[new Date().getDay()];
}
function getPlayoutName(epgFlag = null) {
  // Pull the date from JS
  const saturdayDate = getSaturday(new Date());
  const saturdayFormattedDate = getDateInYYYYMMDDFormat(new Date(saturdayDate));
  const pastSundayDate = getPastSunday(new Date());
  const pastSundayFormattedDate = getDateInYYYYMMDDFormat(new Date(pastSundayDate));
  const dayOfWeek = getDayOfWeek();
  // Stitch together a name based on the Format: GTTV-Playout-{YYYY}-{MM}-{DD}_{YYYY}-{MM}-{DD} where the last Date Group is the saturday of that week.
  switch (true) {
    case epgFlag?.length > 0:
      return `gttv-epg-${pastSundayFormattedDate}_${saturdayFormattedDate}`;
    default:
      return `GTTV-Playout-${pastSundayFormattedDate}_${saturdayFormattedDate}_${dayOfWeek}`;
  }
}
function handleExtraVideos(arrVideos, title) {
  // loop and look for the Duplicate Title
  return new Promise((resolve, reject) => {
    var validatedEpisodes = [...arrVideos];
    for (let i = validatedEpisodes.length - 1; i > 0; i--) {
      const video = validatedEpisodes[i];
      // Strip any automatically generated white spaces or tabs coming from Zype from the title.
      const tabRegex = /^\t/g;
      video.title = video.title.replace(tabRegex, "");
      while (i > 0) {
        var compareVideo = validatedEpisodes[i - 1];
        break;
      }
      if (video.title.replace(/\W/g, "") === compareVideo.title.replace(/\W/g, "")) {
        // check the created_at timestamp
        var ts1 = new Date(video.created_at);
        var ts2 = new Date(compareVideo.created_at);
        // use the newer of the two
        switch (true) {
          case ts1 > ts2:
            // delete compareVideo duplicate from array.
            var index = validatedEpisodes.indexOf(compareVideo);
            validatedEpisodes.splice(index, 1);
            // console.warn(
            //     `Duplicates of ${video.title} were found. Removing (${compareVideo._id}) from the video array.`
            // );
            break;
          case ts1 < ts2:
            // delete video duplicate from array.
            var index = validatedEpisodes.indexOf(video);
            validatedEpisodes.splice(index, 1);
            validatedEpisodes.splice(index, 1);
            break;
          default:
            break;
        }
      }
    }
    // If there are still mulitple videos, take the one with the exact name we're looking for.
    if (validatedEpisodes.length > 1) {
      validatedEpisodes = validatedEpisodes.filter((video) => {
        return video.title === title;
      });
    }
    resolve(validatedEpisodes);
  });
}

function initPlayoutToggle() {
  const toggleRoot = document.getElementById("toggle_root");
  render(<PlayoutToggle isTV></PlayoutToggle>, toggleRoot);
  toggleRoot.classList.remove("animate");
  return;
}
//Check for playout channel version
let playoutData = document.getElementById("playout_channel_data");
let playoutChannelVersion;
let playoutChannelID;

if (playoutData !== null) {
  playoutChannelVersion = playoutData.dataset.playoutVersion;
  playoutChannelID = playoutData.dataset.playoutId;
}

// FailState ID
const failStateID = "5f46eb0e0620d60001d77178";

async function initPlayoutChannel() {
  // if the ID does not match the fail state ID, is 24 characters long and alphanumeric, use that ID. If not, use the fail state.
  if (
    playoutChannelID !== failStateID &&
    playoutChannelID.length == 24 &&
    playoutChannelID.match(/^[0-9a-zA-Z]+$/)
  ) {
    playoutChannelID = playoutChannelID;
  } else {
    playoutChannelID = failStateID;
  }
  // Make a request to the Zype API with that file name.
  let playoutVideoResponse;
  let playoutVideoDataID;

  // if the version is 1.0, or the ACF fields aren't there or coming through correctly, run the following code.
  if (playoutChannelVersion == "1.0" || playoutChannelVersion.length == 0) {
    const playoutName = getPlayoutName();
    playoutVideoResponse = await useZypeAPIPromise("listVideo", null, {
      q: playoutName.toLowerCase(),
    });
    // Get the Zype ID of the video that matched your API request.
    switch (true) {
      case playoutVideoResponse.response.length > 1:
        // Handle Extra Videos function will remove any duplicate titles, and match the playoutName title in the case that the response accidentally  brings  back mulitple playout videos from different weeks
        playoutVideoResponse.response = await handleExtraVideos(
          playoutVideoResponse.response,
          playoutName
        );
        if (playoutVideoResponse.response.length > 1) {
          playoutVideoDataID = failStateID;
          break;
        }
        playoutVideoDataID = playoutVideoResponse?.response[0]?._id
          ? playoutVideoResponse.response[0]._id
          : failStateID;
        break;
      default:
        playoutVideoDataID = playoutVideoResponse?.response[0]?._id
          ? playoutVideoResponse.response[0]._id
          : failStateID;
        break;
    }
    //   Warning if Failstate is used
    if (playoutVideoDataID === failStateID) {
      console.warn(`${playoutName} did not work as expected, playing backup channel`);
    }
  } else {
    playoutVideoResponse = await useZypeAPIPromise("listVideo", playoutChannelID);
    playoutVideoDataID = playoutChannelID;
  }
  // Push that into the Playout Channel area on the homepage.
  const playoutRoot = document.getElementById("playout_root");
  render(
    <PlayoutChannel isLoop isAutoPlay zypeVideoPlayerID={playoutVideoDataID}></PlayoutChannel>,
    playoutRoot
  );
  return;
}

async function initPlayoutSchedule() {
  let epgData = await getEPGData();
  // Push that into the Playout Schedule area on the homepage.
  const epgRoot = document.getElementById("epg_root");
  render(<EPGSchedule EPGData={epgData}></EPGSchedule>, epgRoot);
  epgRoot.classList.remove("animate");
  return;
}

export default {
  init() {},
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
    // initPlayoutToggle();
    initPlayoutChannel();
    initPlayoutSchedule();
  },
};
