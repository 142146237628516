import { useEffect } from "@wordpress/element";
import DayItem from "../DayItem/DayItem";
function getDateWithTimeZoneOffset(inputDate) {
  const offset = inputDate.getTimezoneOffset();
  inputDate = new Date(inputDate.getTime() - offset * 60 * 1000);
  return inputDate;
}

function getDateInYYYYMMDDFormat(inputDate) {
  inputDate = getDateWithTimeZoneOffset(inputDate);
  return inputDate.toISOString().split("T")[0];
}
function getSaturday(inputDate) {
  let resultDate = getDateWithTimeZoneOffset(inputDate);
  return resultDate.setDate(inputDate.getDate() + ((7 + 6 - inputDate.getDay()) % 7));
}
function getPastSunday(inputDate) {
  let resultDate = getDateWithTimeZoneOffset(inputDate);
  // if result date is sunday then use it as the beginning date of the spanning week;
  if (resultDate.getDay() == 0) {
    return resultDate;
    // Set to most recent past Sunday: current date + Day index(0) - current day number
  } else return resultDate.setUTCDate(resultDate.getUTCDate() + 0 - (resultDate.getUTCDay() || 7));
}

export default function HeaderRow({ handleClick, activeDay, onMount }) {
  useEffect(() => {
    onMount(true);
  }, []);
  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  return (
    <div className="header-row">
      {days.map((day, index) => {
        return (
          <DayItem
            sundayDate={getPastSunday(new Date())}
            handleClick={handleClick}
            index={index}
            activeDay={activeDay}
            day={day}
          ></DayItem>
        );
      })}
    </div>
  );
}
