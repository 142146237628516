import NewPassword from "./NewPassword";
import ConfirmPassword from "./ConfirmPassword";
import SubmitButton from "../SubmitButton/SubmitButton";

import { useState } from "@wordpress/element";

export default function PasswordReset({ passwordToken, updateDisplayState }) {
  const [newPassword, updateNewPassword] = useState("");
  const [passwordStrength, updatePasswordStrength] = useState("");
  const [doPasswordsMatch, toggleDoPasswordsMatch] = useState(false);

  function handlePasswordChange(value) {
    updateNewPassword(value);
  }
  function handleStrengthChange(value) {
    updatePasswordStrength(value);
  }
  return (
    <div>
      <NewPassword
        handlePasswordChange={handlePasswordChange}
        handleStrengthChange={handleStrengthChange}
      ></NewPassword>
      <ConfirmPassword newPassword={newPassword} handleMatch={toggleDoPasswordsMatch}></ConfirmPassword>
      <p style={{ color: doPasswordsMatch ? "#0f9d58" : "#000" }}>
        Do passwords match: {doPasswordsMatch ? "Yes" : "No"}
      </p>
      <SubmitButton
        passwordOrEmail={"password"}
        disabled={!doPasswordsMatch}
        newPassword={newPassword}
        passwordStrength={passwordStrength}
        passwordToken={passwordToken}
        updateDisplayState={updateDisplayState}
      ></SubmitButton>
    </div>
  );
}
