import { useEffect } from "@wordpress/element";
import { Button } from "@wordpress/components";
import "./LiveStreamTriggerBox.scss";

function handleWatchLiveClick() {
  window.location.href = "/live";
}

function handleStayHereClick(event, intervalID) {
  const liveStreamBoxRoot = document.getElementById("live_stream_notification_root");
  document.querySelector(".live-stream-trigger-box").classList.remove("visible");
  document.querySelector(".live-stream-trigger-box").classList.add("stay");
  clearInterval(intervalID);
  setTimeout(() => {
    liveStreamBoxRoot.remove();
  }, 501);
}

const WatchLiveButton = () => (
  <Button className="watch-live" isPrimary onClick={handleWatchLiveClick}>
    Watch Live
  </Button>
);

const StayHereButton = (props) => {
  return (
    <Button className="stay-here" isSecondary onClick={(e) => handleStayHereClick(e, props.intervalID)}>
      Stay Here
    </Button>
  );
};

export default function LiveStreamTriggerBox({ intervalObject }) {
  useEffect(() => {
    const hasSeenLiveStream = sessionStorage.getItem('hasSeenLiveStream');
    if (!hasSeenLiveStream) {
      setTimeout(() => {
        document.querySelector(".live-stream-trigger-box").classList.add("visible");
        sessionStorage.setItem('hasSeenLiveStream', 'true');
      }, 501);
    }
  }, []);
  
  return (
    <div id="live_stream_overlay">
      <div className="live-stream-trigger-box">
        <h4>A live stream is active now. Would you like to watch it?</h4>
        <div className="buttons-group">
          <WatchLiveButton></WatchLiveButton>
          <StayHereButton intervalID={intervalObject}></StayHereButton>
        </div>
      </div>
    </div>
  );
}





//PREVIOUS CODE THAT RUNS POPUP ON EVERY PAGE

// import { useEffect, useState } from "@wordpress/element";
// import { Button } from "@wordpress/components";
// import "./LiveStreamTriggerBox.scss";

// function handleWatchLiveClick() {
//   window.location.href = "/live";
// }
// function handleStayHereClick(event, intervalID) {
//   const liveStreamBoxRoot = document.getElementById("live_stream_notification_root");
//   document.querySelector(".live-stream-trigger-box").classList.remove("visible");
//   document.querySelector(".live-stream-trigger-box").classList.add("stay");
//   clearInterval(intervalID);
//   setTimeout(() => {
//     liveStreamBoxRoot.remove();
//   }, 501);
// }

// const WatchLiveButton = () => (
//   <Button className="watch-live" isPrimary onClick={handleWatchLiveClick}>
//     Watch Live
//   </Button>
// );
// const StayHereButton = (props) => {
//   return (
//     <Button className="stay-here" isSecondary onClick={(e) => handleStayHereClick(e, props.intervalID)}>
//       Stay Here
//     </Button>
//   );
// };

// export default function LiveStreamTriggerBox({ intervalObject }) {
//   useEffect(() => {
//     //    add visible class
//     setTimeout(() => {
//       document.querySelector(".live-stream-trigger-box").classList.add("visible");
//     }, 501);
//   }, []);
//   return (
//     <div id="live_stream_overlay">
//       <div className="live-stream-trigger-box">
//         <h4>A live stream is active now. Would you like to watch it?</h4>
//         <div className="buttons-group">
//           <WatchLiveButton></WatchLiveButton>
//           <StayHereButton intervalID={intervalObject}></StayHereButton>
//         </div>
//       </div>
//     </div>
//   );
// }