import { useState, useEffect } from "@wordpress/element";
import HeaderRow from "./HeaderRow/HeaderRow";
import "./FullSchedule.scss";
import ScheduleItem from "./ScheduleItem/ScheduleItem";
import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();

export default function FullSchedule(props) {
  const { EPGData } = props;
  const [weeklySchedule, setWeeklySchedule] = useState();
  const [renderReadyState, toggleRenderReadyState] = useState(false);
  const [activeDay, setActiveDay] = useState();
  const [areChildrenComponentsMounted, toggleChildrenComponentsMounted] = useState(false);
  const [renderStatus, updateRenderStatus] = useState({
    activeDaySet: false,
    renderReady: false,
  });

  function enumeratedEPGDataIntoDays(epgArray) {
    //   Use Start time with offset and converted to Denver timezone, to calc which day "sun-sat" the response objects belong in.
    let epgSplitScheduleByDay = [[]]; // This is the initial creation of the empty "sunday" index.
    epgArray.forEach((epgItem) => {
      const startDate = new Date(epgItem.start_time_with_offset).toLocaleDateString("en", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        timeZone: "America/Denver",
      });
      const daysArray = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ];
      const day = startDate.substring(0, startDate.indexOf(","));
      const dayIndex = daysArray.indexOf(day.toLowerCase());
      let isFirstEntryOfDay = epgSplitScheduleByDay[dayIndex].length == 0; // assumes epg starts on sunday, see: line 21 to see it's empty
      if (isFirstEntryOfDay) {
        epgSplitScheduleByDay.splice(dayIndex, 0, [epgItem]); // replaces intial empty array with first epg item, and pushes the empty array to the next day.
      } else {
        epgSplitScheduleByDay[dayIndex].push(epgItem); // fills in the day according to the index.
      }
    });
    return epgSplitScheduleByDay;
  }

  function getDateWithTimeZoneOffset(inputDate) {
    const offset = inputDate.getTimezoneOffset();
    inputDate = new Date(inputDate.getTime() - offset * 60 * 1000);
    return inputDate;
  }

  function calculateScrollDisplacementForDayItem(element, index, padding) {
    // dataset.width is set because offsetWidth is wonky with react elements.
    let elementWidth = parseInt(element.dataset.width);
    let halfWidth = elementWidth * 0.5;
    let displacementBase = elementWidth + padding;
    let scrollDisplacement = index * displacementBase - halfWidth;
    return scrollDisplacement;
  }
  function scrollToActiveDayMenuItem() {
    let activeDayItemElement = document.querySelector(".day-item.active");
    let displacement = calculateScrollDisplacementForDayItem(
      activeDayItemElement,
      activeDayItemElement.dataset.dayOfWeek,
      10
    ); /* 10px grid gap*/
    return new Promise((resolve) => {
      resolve(
        activeDayItemElement.parentElement.scroll({ left: displacement, behavior: "smooth" })
      );
    });
  }
  function scrollToActiveScheduleItem() {
    let activeScheduleItemElement = document.querySelector(".schedule-item.is-now-playing");
    activeScheduleItemElement.scrollIntoView({ behavior: "smooth" });
    activeScheduleItemElement.classList.add("active-flash");
  }
  function handleScheduleMenuItemClick(e) {
    let toggleElement = e.target;
    let dayToggleIndex = parseInt(toggleElement.dataset?.dayOfWeek);
    // update state
    modifyRenderStatusKey("activeDaySet", false);
    modifyRenderStatusKey("renderReady", false);
    setActiveDay(dayToggleIndex);
    // animate scroll
    let displacement = calculateScrollDisplacementForDayItem(
      toggleElement,
      dayToggleIndex,
      10
    ); /* 10px grid gap*/
    toggleElement.parentElement.scroll({ left: displacement, behavior: "smooth" });
  }
  function modifyRenderStatusKey(key, flag = true) {
    let newRenderStatus = { ...renderStatus };
    newRenderStatus[key] = flag;
    updateRenderStatus(newRenderStatus);
  }
  function determineNowPlayingItem(EPGData) {
    const scheduleItems = EPGData;
    for (const [index, scheduleItem] of scheduleItems.entries()) {
      // Get Current Time
      const currentTime = new Date();
      // Get Start Time of scheduleItem
      const startTimeOffset = new Date(scheduleItem.start_time_with_offset);
      // Get End Time of scheduleItem
      const endTimeOffset = new Date(scheduleItem.end_time_with_offset);
      //   Compare
      if (currentTime > endTimeOffset) {
        continue;
      } else if (currentTime >= startTimeOffset && currentTime < endTimeOffset) {
        scheduleItem.nowPlaying = true;
        return { data: scheduleItem, index };
      }
    }
  }
  useEffect(() => {
    determineNowPlayingItem(EPGData);
    const enumeratedData = enumeratedEPGDataIntoDays(EPGData);
    setWeeklySchedule(enumeratedData);
  }, []);
  useEffect(() => {
    if (weeklySchedule) {
      const currentDay = getDateWithTimeZoneOffset(new Date()).getDay();
      setActiveDay(currentDay);
    }
  }, [weeklySchedule]);
  useEffect(() => {
    if (activeDay || activeDay === 0) {
      modifyRenderStatusKey("activeDaySet");
    }
  }, [activeDay]);
  useEffect(() => {
    switch (true) {
      case renderStatus.renderReady:
        toggleRenderReadyState(true);
        break;
      case renderStatus.activeDaySet:
        modifyRenderStatusKey("renderReady");
        break;
      default:
        //   Not Render Ready
        toggleRenderReadyState(false);
        break;
    }
  }, [renderStatus]);
  useEffect(() => {
    if (renderReadyState) {
    }
  }, [renderReadyState]);
  useEffect(() => {
    if (areChildrenComponentsMounted) {
      scrollToActiveDayMenuItem().then(() => {
        setTimeout(() => {
          scrollToActiveScheduleItem();
        }, 500);
      });
    }
  }, [areChildrenComponentsMounted]);

  const HourlyGrid = ({ week }) => {
    return (
      <div className="hourly-grid">
        {week.map((item, index) => {
          return (
            <ScheduleItem
              data={item}
              isAlternate={index % 2 !== 0}
              isNowPlaying={item?.nowPlaying}
            />
          );
        })}
      </div>
    );
  };

  return (
    renderReadyState && (
      <div id="full_schedule">
        <HeaderRow
          onMount={toggleChildrenComponentsMounted}
          handleClick={handleScheduleMenuItemClick}
          activeDay={activeDay}
        ></HeaderRow>
        <HourlyGrid week={weeklySchedule[activeDay]}></HourlyGrid>
      </div>
    )
  );
}
