import { useState, useEffect } from "@wordpress/element";
import { ReactComponent as Checkmark } from "../../../../../src/lib/icons/checkmark.svg";

export default function NewPassword({ handlePasswordChange, handleStrengthChange }) {
  const [strengthColor, setStrengthColor] = useState("#db4437");
  const [passwordStrength, setPasswordStrength] = useState("weak");
  const [passwordStrengthChecks, modifyPasswordStrengthChecks] = useState({
    uppercase: false,
    lowercase: false,
    digit: false,
    special: false,
    length: false,
  });
  const [passwordValue, setPasswordValue] = useState();
  function gaugePasswordStrength(checks) {
    let counter = 0;
    for (const key of Object.keys(checks)) {
      if (checks[key]) {
        counter++;
      }
    }
    let strengthValue = Math.ceil((1 - (Object.keys(checks).length - counter) / Object.keys(checks).length) * 100);
    switch (true) {
      case strengthValue >= 100:
        return "strong";
      case strengthValue >= 80:
        return "medium";
      case strengthValue >= 20:
        return "weak";
      default:
        return "weak";
    }
  }
  function analyzePasswordStrength(event) {
    const upperCaseLetterRegex = /(?=.*[A-Z])/; // should contain at least one upper case
    const lowerCaseLetterRegex = /(?=.*[a-z])/; // should contain at least one lower case
    const digitRegex = /(?=.*?[0-9])/; // should contain at least one digit
    const specialCharRegex = /(?=.*?[!@#\$&*~])/; // should contain at least one Special character
    const lengthRegex = /.{8,}/; // Must be at least 8 characters in length
    const passwordValue = event.target.value;
    let updatedPasswordStrengthChecker = {
      uppercase: upperCaseLetterRegex.test(passwordValue),
      lowercase: lowerCaseLetterRegex.test(passwordValue),
      digit: digitRegex.test(passwordValue),
      special: specialCharRegex.test(passwordValue),
      length: lengthRegex.test(passwordValue),
    };
    modifyPasswordStrengthChecks(updatedPasswordStrengthChecker);
    let strengthResult = gaugePasswordStrength(updatedPasswordStrengthChecker);
    switch (strengthResult) {
      case "strong":
        setStrengthColor("#0f9d58");
        break;
      case "medium":
        setStrengthColor("#f4b400");
        break;
      default:
        setStrengthColor("#db4437");
        setPasswordStrength("weak");
        break;
    }
    setPasswordStrength(strengthResult);
    setPasswordValue(passwordValue);
  }
  useEffect(() => {
    if (passwordStrengthChecks) {
      handlePasswordChange(passwordValue);
      handleStrengthChange(passwordStrength);
    }
  }, [passwordStrengthChecks]);
  return (
    <div>
      <p>
        <label htmlFor="password">New Password: </label>
      </p>
      <p>
        <input
          type="password"
          name="password"
          onChange={(e) => {
            analyzePasswordStrength(e);
          }}
        />
      </p>
      <p style={{ color: strengthColor }}>
        Password strength: {passwordStrength.charAt(0).toUpperCase() + passwordStrength.slice(1)}
      </p>
      <ul>
        <li key="uppercase" style={passwordStrengthChecks.uppercase ? { color: "#0f9d58" } : { color: "#db4437" }}>
          At least 1 Uppercase letter
        </li>
        <li key="lowercase" style={passwordStrengthChecks.lowercase ? { color: "#0f9d58" } : { color: "#db4437" }}>
          At least 1 Lowercase letter
        </li>
        <li key="digit" style={passwordStrengthChecks.digit ? { color: "#0f9d58" } : { color: "#db4437" }}>
          At least 1 Digit
        </li>
        <li key="special" style={passwordStrengthChecks.special ? { color: "#0f9d58" } : { color: "#db4437" }}>
          At least 1 Special Character
        </li>
        <li key="length" style={passwordStrengthChecks.length ? { color: "#0f9d58" } : { color: "#db4437" }}>
          At least 8 Characters Long
        </li>
      </ul>
    </div>
  );
}
