/*------------------------------------------
       REMEMBER ALL TIMES EASTERN
------------------------------------------ */
import { render, useEffect, useState } from "@wordpress/element";
import { Button } from "@wordpress/components";
import Modal from "../Modal/Modal";
import NowPlaying from "./NowPlaying/NowPlaying";
import UpcomingItems from "./UpcomingItems/UpcomingItems";
import "./EPGSchedule.scss";

function getDateWithTimeZoneOffset(inputDate) {
  const offset = inputDate.getTimezoneOffset();
  inputDate = new Date(inputDate.getTime() - offset * 60 * 1000);
  return inputDate;
}

export default function EPGSchedule({ EPGData }) {
  //   if EPGData = undefined return null
  if (!EPGData?.length) {
    console.log("epgData", EPGData);
    console.warn(
      "WARNING: Something went wrong with the EPGData. Check Zype admin. https://admin.zype.com/program_guides"
    );
    return null;
  }
  const [nowPlayingState, updateNowPlayingState] = useState({ data: null, index: null });
  const [upcomingItemsState, updateUpcomingItems] = useState([]);
  const [componentReadyState, toggleComponentReadyState] = useState({
    nowPlaying: false,
    upcomingItems: false,
    renderReady: false,
  });
  const [renderReadyStatus, toggleRenderReadyStatus] = useState(false);

  function determineNowPlayingItem(EPGData) {
    const scheduleItems = EPGData;
    for (const [index, scheduleItem] of scheduleItems.entries()) {
      // Get Current Time
      const currentTime = new Date();
      // Get Start Time of scheduleItem
      const startTimeOffset = new Date(scheduleItem.start_time_with_offset);
      // Get End Time of scheduleItem
      const endTimeOffset = new Date(scheduleItem.end_time_with_offset);
      //   Compare
      if (currentTime > endTimeOffset) {
        continue;
      } else if (currentTime >= startTimeOffset && currentTime < endTimeOffset) {
        return { data: scheduleItem, index };
      }
    }
  }
  function determineUpcomingItems(EPGData) {
    let upcomingItemsArray = EPGData.slice(nowPlayingState.index + 1, nowPlayingState.index + 4);
    let dataPacket;
    if (upcomingItemsArray.length == 0) {
      dataPacket = ["No Upcoming Items"];
    } else {
      dataPacket = upcomingItemsArray.map((item) => {
        let index = EPGData.indexOf(item);
        const itemState = { data: item, index };
        return itemState;
      });
    }
    return dataPacket;
  }
  // Mount
  //  Determine Now Playing State
  useEffect(() => {
    const nowPlayingItem = determineNowPlayingItem(EPGData);
    updateNowPlayingState(nowPlayingItem);
  }, []);

  //   Update next 3 upcoming schedule items
  useEffect(() => {
    if (nowPlayingState.index !== null) {
      const upcomingItemsDataPacket = determineUpcomingItems(EPGData);
      updateUpcomingItems(upcomingItemsDataPacket);
      const updatedReadyState = { ...componentReadyState };
      updatedReadyState.nowPlaying = true;
      toggleComponentReadyState(updatedReadyState);
    }
  }, [nowPlayingState]);

  // Once Upcoming Items are determined, render ready status
  useEffect(() => {
    // ready
    if (upcomingItemsState.length > 0) {
      const updatedReadyState = { ...componentReadyState };
      updatedReadyState.upcomingItems = true;
      toggleComponentReadyState(updatedReadyState);
    }
  }, [upcomingItemsState]);
  useEffect(() => {
    switch (true) {
      case componentReadyState.renderReady:
        toggleRenderReadyStatus(true);
        break;
      case componentReadyState.nowPlaying && componentReadyState.upcomingItems:
        const updatedReadyState = { ...componentReadyState };
        updatedReadyState.renderReady = true;
        toggleComponentReadyState(updatedReadyState);
        break;
      default:
        //   Not ready
        break;
    }
  }, [componentReadyState]);
  //   SEE ALL MODAL
  function handleSeeAllClick() {
    const modalRoot = document.getElementById("modal_root");
    render(<Modal isEPG EPGData={EPGData}></Modal>, modalRoot);
  }
  const SeeAllButton = () => (
    <Button className="see-all-button" isPrimary onClick={handleSeeAllClick}>
      See All
    </Button>
  );
  return (
    <>
      {renderReadyStatus && (
        <>
          <div className="header-bar">
            <h3 className="playing">Playing</h3>
            <h3 className="playing-next">Playing Next</h3>
          </div>
          <div className="player-bar">
            <NowPlaying nowPlayingData={nowPlayingState.data}></NowPlaying>
            <UpcomingItems upcomingItems={upcomingItemsState}></UpcomingItems>
          </div>
          <div className="see-all-bar">
            <SeeAllButton></SeeAllButton>
          </div>
        </>
      )}
    </>
  );
}
