import React from "react";
import Overlay from "../Overlay/Overlay";

import { UseTruncateWords, UseTruncateChars } from "../../util/useTruncate";
import "./TileProto.scss";

function TileProto(props) {
  const TILE_DATA = props.data;
  //   Dont know where isCurrentlyPlaying is coming from;
  let isCurrentlyPlaying = false;

  return (
    <div key={TILE_DATA._id} id={TILE_DATA._id} className="zype-playlist-tile">
      <div className={`playlist-thumbnail`} data-tile-id={TILE_DATA._id} onClick={props.action}>
        <img
          src={
            TILE_DATA?.thumbnails?.[1]?.url ||
            "/wp-content/themes/gttv-2021/src/lib/images/default-gttv-thumb-854x480.jpg"
          }
          alt={`Thumbnail for ${TILE_DATA.title}`}
        ></img>
        <Overlay isCurrentlyPlaying={isCurrentlyPlaying} isPremium={TILE_DATA.subscription_required} />
      </div>
      <div className="playlist-title">{UseTruncateChars(TILE_DATA.title, 54)}</div>
    </div>
  );
}
export default TileProto;
