import React, { useState } from "react";
import Input from "../Input/Input";
import Button from "../Button/Button";

import styles from "./SearchBar.module.scss";

function SearchBar(props) {
  return (
    <div className={styles["search-bar"]}>
      <Input
        id="search_input"
        label="Search for teachings, events, livestreams, and more!"
        placeholder="Campus Days 2019..."
      />
      <Button
        text="Search"
        variant="primary"
        onClick={() => {
          let input = document.getElementById("search_input");
          props.action(input.value);
        }}
      />
    </div>
  );
}
export default SearchBar;
